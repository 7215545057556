import React from 'react'
import THEME from 'utils/theme'

export default function CloseIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg
      viewBox='0 0 28.91 32.23'
      aria-labelledby='close-icon'
      className='svgIcon iconClose'
      style={{ width, height, fill: color }}
    >
      <title id='close-icon'>Close The Menu</title>
      <path d='M17.12,16.12,28.41,3.32a2,2,0,0,0-3-2.65l-11,12.42L3.5.68a2,2,0,1,0-3,2.65L11.79,16.12.5,28.91a2,2,0,0,0,3,2.65l11-12.42,11,12.42a2,2,0,0,0,3-2.65Z' />
    </svg>
  )
}
