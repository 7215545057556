export const FB_PIXEL_ID = process.env.FB_RNR_ID

export const pageview = () => {
  try {
    window.fbq('track', 'PageView')
  } catch (err) {
    console.log('fb pageview error', err)
  }
}

export const sendEvent = (name, options = {}) => {
  try {
    window.fbq('track', name, options)
  } catch (err) {
    console.log('fb event err', err)
  }
}
