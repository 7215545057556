import decode from 'jwt-decode'
import { v4 as uuid } from 'uuid'
import { isServer } from 'utils/server'
import { add } from 'date-fns'
import { isPast } from 'date-fns'
import { formatISO } from 'date-fns'
import { parseISO } from 'date-fns'

const StorageKeys = Object.freeze({
  authToken: 'BS_AUTH_TOKEN',
  clientId: 'BS_CLIENT_ID',
  userAccount: 'BS_USER_ACCOUNT',
  userType: 'BS_USER_TYPE',
  idempotencyKey: 'BS_IDEMPOTENCY_KEY',
  legacyAuthToken: 'user'
})

class Storage {
  constructor() {
    this._generateClientId()
  }

  get clientId() {
    return this._get(StorageKeys.clientId)
  }

  get authToken() {
    return this._get(StorageKeys.authToken)
  }

  set authToken(newValue) {
    this._set(StorageKeys.authToken, newValue)
  }

  get userAccount() {
    const user = this._getJSON(StorageKeys.userAccount)
    if (!user) {
      return null
    }
    const dateExpires = parseISO(user.expires_at)
    if (isPast(dateExpires)) {
      return null
    }
    return user.data
  }

  set userAccount(data) {
    if (!data) {
      this._setJSON(StorageKeys.userAccount, null)
    } else {
      const created = formatISO(new Date())
      const expires = formatISO(add(new Date(), { days: 1 }))
      this._setJSON(StorageKeys.userAccount, {
        data,
        created_at: created,
        expires_at: expires
      })
    }
  }

  get userType() {
    const type = this._get(StorageKeys.userType)
    return type
  }

  set userType(type) {
    this._set(StorageKeys.userType, type)
  }

  get userId() {
    const authToken = this._get(StorageKeys.authToken)
    if (!authToken) return null
    const decodedToken = decode(authToken)
    return decodedToken.data.id
  }

  get loggedIn() {
    return !!this.authToken
  }

  getIdempotencyKey(eventId) {
    // build key by concatenating StorageKeys.idempotencyKey with eventId and userId.
    // This is to namespace each idempotency key by user and event, allows each idempotency key to be
    // unneffected by idempotency keys associated with other users and events
    let value = this._get(`${StorageKeys.idempotencyKey}_${this.userId}_${eventId}`)
    if (!value) {
      // if there isn't an existing value for this key, generate a new uuid and call setter to
      // persist the key/value pair. This approach allows callers of this getter to require no knowledge
      // about whether a value for this key already exists. If one is already present it will be reused
      // otherwise a new one will be generated on the fly
      value = uuid()
      this.setIdempotencyKey(eventId, value)
    }
    return value
  }

  setIdempotencyKey(eventId, newValue) {
    // build key by concatenating StorageKeys.idempotencyKey with eventId and userId
    this._set(`${StorageKeys.idempotencyKey}_${this.userId}_${eventId}`, newValue)
  }

  _generateClientId() {
    if (this._get(StorageKeys.clientId)) {
      return
    }
    this._set(StorageKeys.clientId, uuid())
  }

  _getJSON(key) {
    try {
      const value = this._get(key)
      return JSON.parse(value)
    } catch (err) {
      return null
    }
  }

  _setJSON(key, value) {
    try {
      const json = JSON.stringify(value)
      return this._set(key, json)
    } catch (err) {
      return false
    }
  }

  _get(key) {
    if (isServer) {
      return null
    }
    return window.localStorage.getItem(key)
  }

  _set(key, newValue) {
    if (isServer) {
      return false
    }
    if (newValue) {
      return window.localStorage.setItem(key, newValue)
    } else {
      return window.localStorage.removeItem(key)
    }
  }
}

export default new Storage()
