/* eslint-disable no-unused-vars */

export const handleMessage = (event, callback) => {
  const url = 'https://www.barstoolsports.com'
  if (event.origin.startsWith(url)) {
    try {
      const data = JSON.parse(event.data)
      const hasAnonID = data?.ajs_anonymous_id
      const hasBarstoolUserID = data?.ajs_user_id

      if (window != undefined && window.whoamiSDK) {
        const whoamiSDK = window.whoamiSDK
        if (hasAnonID) {
          whoamiSDK.setAnonymousID(hasAnonID)
        }
        if (hasBarstoolUserID) {
          whoamiSDK.identify({ barstoolUserID: hasBarstoolUserID })
        }
        if (hasAnonID) callback?.()
        // Once successfully setup, remove listener
        window.removeEventListener('message', handleMessage)
      }
    } catch (error) {
      console.error('Error parsing message data:', error)
    }
  }
}

export function track(event, content) {
  if (!window.whoamiSDK) return
  return window.whoamiSDK?.track(event, content)
}

export function identify(content) {
  if (!window.whoamiSDK) return
  return window.whoamiSDK?.identify(content)
}

export function page() {
  if (!window.whoamiSDK) return
  return window.whoamiSDK?.page()
}
