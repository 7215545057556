import ExternalScriptLoader from 'lib/external-script-loader'

class CriteoPixel extends ExternalScriptLoader {
  scriptUrl = 'https://static.criteo.net/js/ld/ld.js'
  options = { defer: true }

  init() {
    window.criteo_q = window.criteo_q || []
    const deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
      ? 'm'
      : 'd'
    window.criteo_q.push(
      { event: 'setAccount', account: 16200 },
      { event: 'setEmail', email: '' },
      { event: 'setSiteType', type: deviceType },
      { event: 'viewHome' }
    )
  }
}

export default new CriteoPixel()
