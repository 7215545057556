export const customerNoPaymentSource = {
  id: 'cus_FYOrO2RkaiWt8d',
  object: 'customer',
  address: null,
  balance: 0,
  created: 1564587954,
  currency: 'usd',
  default_source: null,
  delinquent: false,
  description: null,
  discount: null,
  email: 'test@barstoolsports.com',
  invoice_prefix: '4C0B9037',
  invoice_settings: {
    custom_fields: null,
    default_payment_method: null,
    footer: null
  },
  livemode: false,
  metadata: {
    user_id: '123456',
    email: 'test@barstoolsports.com',
    username: 'test'
  },
  name: null,
  next_invoice_sequence: 3,
  phone: null,
  preferred_locales: [],
  shipping: null,
  sources: {
    object: 'list',
    data: [
      {
        id: 'card_1KHyaoGa4G5ohdzFhhrFID81',
        object: 'card',
        address_city: null,
        address_country: null,
        address_line1: null,
        address_line1_check: null,
        address_line2: null,
        address_state: null,
        address_zip: '24224',
        address_zip_check: 'pass',
        brand: 'Visa',
        country: 'US',
        customer: 'cus_FYOrO2RkaiWt8d',
        cvc_check: 'pass',
        dynamic_last4: null,
        exp_month: 4,
        exp_year: 2033,
        fingerprint: 'Y9ZI4gsXOxoF1mkT',
        funding: 'credit',
        last4: '4242',
        metadata: {},
        name: null,
        tokenization_method: null
      }
    ],
    has_more: false,
    total_count: 1,
    url: '/v1/customers/cus_FYOrO2RkaiWt8d/sources'
  },
  tax_exempt: 'none',
  tax_ids: {
    object: 'list',
    data: [],
    has_more: false,
    total_count: 0,
    url: '/v1/customers/cus_FYOrO2RkaiWt8d/tax_ids'
  }
}

export const customer = {
  id: 'cus_FYOrO2RkaiWt8d',
  object: 'customer',
  address: null,
  balance: 0,
  created: 1564587954,
  currency: 'usd',
  default_source: {
    id: 'card_1HKaoyGa3G5ozdhHffxIFD20',
    object: 'card',
    address_city: null,
    address_country: null,
    address_line1: null,
    address_line1_check: null,
    address_line2: null,
    address_state: null,
    address_zip: '24224',
    address_zip_check: 'pass',
    brand: 'Visa',
    country: 'US',
    customer: 'cus_FYOrO2RkaiWt8d',
    cvc_check: 'pass',
    dynamic_last4: null,
    exp_month: 4,
    exp_year: 2033,
    fingerprint: 'Y9ZI4gsXOxoF1mkT',
    funding: 'credit',
    last4: '4242',
    metadata: {},
    name: null,
    tokenization_method: null
  },
  delinquent: false,
  description: null,
  discount: null,
  email: 'test@barstoolsports.com',
  invoice_prefix: '4C0B9037',
  invoice_settings: {
    custom_fields: null,
    default_payment_method: null,
    footer: null
  },
  livemode: false,
  metadata: {
    user_id: '123456',
    email: 'test@barstoolsports.com',
    username: 'test'
  },
  name: null,
  next_invoice_sequence: 3,
  phone: null,
  preferred_locales: [],
  shipping: null,
  sources: {
    object: 'list',
    data: [
      {
        id: 'card_1KHyaoGa4G5ohdzFhhrFID81',
        object: 'card',
        address_city: null,
        address_country: null,
        address_line1: null,
        address_line1_check: null,
        address_line2: null,
        address_state: null,
        address_zip: '24224',
        address_zip_check: 'pass',
        brand: 'Visa',
        country: 'US',
        customer: 'cus_FYOrO2RkaiWt8d',
        cvc_check: 'pass',
        dynamic_last4: null,
        exp_month: 4,
        exp_year: 2033,
        fingerprint: 'Y9ZI4gsXOxoF1mkT',
        funding: 'credit',
        last4: '4242',
        metadata: {},
        name: null,
        tokenization_method: null
      }
    ],
    has_more: false,
    total_count: 1,
    url: '/v1/customers/cus_FYOrO2RkaiWt8d/sources'
  },
  tax_exempt: 'none',
  tax_ids: {
    object: 'list',
    data: [],
    has_more: false,
    total_count: 0,
    url: '/v1/customers/cus_FYOrO2RkaiWt8d/tax_ids'
  }
}
