import { TYPE_FORMS } from 'constants/typeform'
import { observable, flow } from 'mobx'

const DEFAULT_REDIRECT_PATH = '/'
const NAVIGATION_ITEMS = [
  // { title: 'Upcoming Events', href: '/events', primary: true, mobile: true },
  // { title: 'Attend Event', href: '#', primary: true, hasDropdown: false },
  // { title: 'Fighter Sign Up', href: TYPE_FORMS.fighter_signup_url, primary: true, hasDropdown: false },
  // { title: 'Ring Girl Sign Up', href: TYPE_FORMS.ring_girl_signup_url, primary: true, hasDropdown: false }
  // { title: 'FAQ', href: '/faq', primary: true, hasDropdown: false }
  { title: 'Ring Girl Voting', href: TYPE_FORMS.ring_girl_voting_url, primary: true, hasDropdown: false }
]

export const NavigationStore = () => {
  const updatePostAuthRedirect = flow(function* (path = DEFAULT_REDIRECT_PATH) {
    this.postAuthRedirect = path
    yield
  })
  const updatePostAuthCallback = flow(function* (callback) {
    this.postAuthCallback = callback
    yield
  })
  const open = flow(function* (item) {
    this.currentDropdown = item
    document.querySelector('.layout__overlay')?.classList.add('layout__overlay--active')
    yield
  })
  const close = flow(function* () {
    this.currentDropdown = null
    document.querySelector('.layout__overlay')?.classList.remove('layout__overlay--active')
    yield
  })

  return observable({
    postAuthRedirect: DEFAULT_REDIRECT_PATH,
    postAuthCallback: null,
    updatePostAuthRedirect,
    updatePostAuthCallback,
    navigationItems: NAVIGATION_ITEMS,
    open,
    close,
    currentDropdown: null
  })
}

NavigationStore.cacheKey = 'NavigationStore'

export default NavigationStore
