import React from 'react'

function AnnouncementBanner({ children }) {
  return (
    <>
      <div className='announcement-banner'>{children}</div>
      <style jsx>{`
        .announcement-banner {
          background-color: #c61010;
          color: #fff;
          font-size: 14px;
          text-align: center;
          padding: 12px 8px;
          width: 100%;
          font-weight: 500;
        }
        .announcement-banner a {
          color: #fff;
          text-decoration: underline;
          font-weight: bold;
        }
      `}</style>
    </>
  )
}

export default AnnouncementBanner
