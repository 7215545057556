import React from 'react'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { string } from 'prop-types'
import { truncateHtmlString } from 'utils/text'

const defaultDescription =
  "Rough N' Rowdy Brawl is fighters with no defense throwing haymakers. It's the funniest most action packed night you'll ever have. Join us for our next event."
const shareImage = process.env.NEXT_PUBLIC_OG_IMAGE

const Head = ({
  noIndexNoFollow = false,
  noAutoZoom = false,
  story = null,
  event = null,
  videoSource = null,
  canonical = null,
  twitterHandler = '@roughnrowdy',
  children = null,
  playlist = null,
  ...props
}) => {
  const router = useRouter()
  const pathname = router.asPath.split('?')[0]
  const url = canonical ?? `${process.env.SITE_URL}${pathname}`
  const truncatedStoryHtml = truncateHtmlString(story?.post_type_meta?.standard_post?.raw_content ?? '', 200)

  let headContent = (
    <>
      <title>{props.title || 'Rough N’ Rowdy | RnR'}</title>
      <meta name='description' content={props.description || defaultDescription} />
      <link rel='canonical' href={url} />

      <meta property='og:url' content={url} />
      <meta property='og:title' content={props.title || ''} />
      <meta property='og:description' content={props.description || defaultDescription} />
      <meta property='og:image' content={shareImage} />
      <meta property='og:image:width' content={1200} />
      <meta property='og:image:height' content={700} />
      <meta property='og:type' content={props.ogType || 'website'} />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content={twitterHandler} />
      <meta name='twitter:image' content={shareImage} />
    </>
  )

  if (story) {
    const title = `${story.title} | RNR`
    headContent = (
      <>
        <title>{title}</title>
        <meta name='description' content={truncatedStoryHtml} />
        <link rel='canonical' href={story.urls.canonical} />
        <meta name='date' content={story.date} />
        <meta name='title' content={story.title} />
        <meta name='author' content={story.author.name} />
        <meta name='date' content={story.date} />
        <meta name='news_keywords' content={story.tags.join(', ')} />
        <meta property='article:publisher' content='roughnrowdy' />

        <meta property='og:url' content={story.urls.canonical} />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={story.title} />
        <meta property='og:description' content={truncatedStoryHtml} />
        <meta property='og:image' content={`${story.thumbnail.raw}`} />

        {story.author.twitter_handle && <meta name='twitter:creator' content={`@${story.author.twitter_handle}`} />}
        <meta name='twitter:title' content={story.title} />
        <meta name='twitter:description' content={truncatedStoryHtml} />
        <meta name='twitter:image' content={`${story.thumbnail.raw}`} />
        <meta name='twitter:image:alt' content={story.title} />
      </>
    )
  } else if (playlist) {
    const image = playlist.thumbnail.location + playlist.thumbnail.large
    headContent = (
      <>
        <title>{`${playlist.title} - Rough N Rowdy`}</title>
        <link rel='canonical' href={url} />
        <meta property='og:title' content={playlist.title} />
        <meta property='og:image' content={image} />
        <meta property='og:type' content='article' />
        <meta property='og:url' content={props.canonical} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content={twitterHandler} />
        <meta name='twitter:title' content={playlist.title} />
        <meta name='twitter:image' content={image} />
        <meta name='twitter:image:alt' content={playlist.title} />
      </>
    )
  }

  const hlsVideoSource = videoSource?.sources.find(({ src, type }) => {
    return ['application/vnd.apple.mpegurl', 'application/x-mpegURL'].includes(type) && src.startsWith('https')
  })

  const eventObjectContent = event && (
    <script key='event-schema' type='application/ld+json'>
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: event.name,
        startDate: event.date,
        eventStatus: 'https://schema.org/EventScheduled',
        eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
        location: {
          '@type': 'VirtualLocation',
          url: 'https://www.roughnrowdybrawl.com/'
        },
        image: [event.thumbnail],
        description: event.description,
        isAccessibleForFree: false,
        offers: {
          '@type': 'Offer',
          availability: 'https://schema.org/InStock',
          price: `${event.price}`,
          priceCurrency: 'USD'
        },
        organizer: {
          '@type': 'Organization',
          name: event.series?.name ?? 'Rough N Rowdy',
          url: 'https://www.roughnrowdybrawl.com/'
        }
      })}
    </script>
  )

  const videoObjectContent = videoSource && (
    <script key='video-schema' type='application/ld+json'>
      {JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'VideoObject',
        name: story.title,
        description: truncatedStoryHtml,
        thumbnailUrl: [story.thumbnail.desktop],
        uploadDate: story.date
      })}
    </script>
  )

  const videoHeadContent = hlsVideoSource && (
    <>
      <meta key='og:video' property='og:video' content={hlsVideoSource.src} />
      <meta key='og:video:type' property='og:video:type' content={hlsVideoSource.type} />
    </>
  )

  return (
    <NextHead>
      <meta charSet='UTF-8' />
      <meta name='viewport' content={`width=device-width, initial-scale=1${noAutoZoom ? ', maximum-scale=1' : ''}`} />
      <link rel='icon' href='/favicon.ico' />

      <link rel='preconnect' href='https://ppv-api.barstoolsports.com/'></link>
      <link rel='preconnect' href='https://union.barstoolsports.com'></link>
      <link rel='preconnect' href='https://chumley.barstoolsports.com'></link>

      <meta name='twitter:site' content={twitterHandler} />
      <meta name='twitter:card' content='summary_large_image' />

      {/* Page specific head content */}
      {headContent}

      {/* Event specific head content */}
      {eventObjectContent}

      {/* Video specific head content */}
      {videoHeadContent}
      {videoObjectContent}

      {/* Disable crawlers */}
      {noIndexNoFollow && <meta name='robots' content='noindex, nofollow' />}

      {/* Render children */}
      {children}
    </NextHead>
  )
}

Head.propTypes = {
  title: string,
  description: string,
  url: string,
  ogImage: string
}

export default Head
