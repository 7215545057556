import React from 'react'
import { observer } from 'mobx-react-lite'
import Link from 'next/link'
import THEME from 'utils/theme'

const NotFound = ({ statusCode, errorMessage }) => {
  return (
    <>
      <div className='ohNoCard'>
        <header className='ohNoCardContent'>
          <h1>
            {statusCode || 404}
            <span>{errorMessage || `This Page Doesn't Exist.`}</span>
          </h1>
          <Link href='/' legacyBehavior>
            <a className='btn btn--primary'>Go to home page</a>
          </Link>
        </header>
      </div>
      <style jsx>{`
        .btn {
          letter-spacing: 0.09rem;
          padding: 0.9rem 2.5rem;
        }
        .ohNoCard {
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: center;
          align-content: center;
          margin: 0 auto 0.5rem auto;
          overflow: hidden;
        }
        .ohNoCardImage {
          display: flex;
          align-self: flex-start;
          width: 100%;
          overflow: hidden;
          text-align: center;
        }
        .ohNoCardContent {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          align-items: center;
          align-content: center;
          padding: 1rem 1rem 2rem;
          text-align: center;
          color: #fff;
        }
        .ohNoCardContent p {
          font-size: 1.25em;
          margin-bottom: 0.5rem;
        }
        .ohNoCardContent h1 {
          font-family: ${THEME.FONTS.BEBAS};
          text-transform: uppercase;
          color: #fff;
          font-size: 3rem;
          font-weight: normal;
          line-height: 120%;
          margin-top: 0;
          margin-bottom: 0.25em;
        }
        .ohNoCardContent h1 span {
          font-family: ${THEME.FONTS.HELVETICA};
          display: block;
          font-weight: bold;
          font-size: 1.5rem;
          line-height: 1;
          text-transform: capitalize;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .ohNoCardContent {
            padding-bottom: 3rem;
          }
          .ohNoCardContent h1 {
            font-size: 6.5rem;
          }
        }
      `}</style>
    </>
  )
}

export default observer(NotFound)
