import React, { useEffect, useState } from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { Elements } from '@stripe/react-stripe-js'

import stripe from 'lib/stripe'
import { setGlobalLogging } from 'lib/logger'
import { BugsnagProvider } from 'lib/bugsnag'
import { loadPixels } from 'lib/pixels'
import quantcast from 'lib/quantcast'

import { usePageProgress, useStore } from 'hooks'
import { NavigationStore } from 'stores/navigation'
import { AuthStore } from 'stores/auth'

import { ErrorProvider } from './_error-provider'

import { handleMessage, page } from 'lib/whoami'
import { useWhoamiIdentification } from 'hooks'
import { FB_PIXEL_ID } from 'lib/pixels/facebook'

const isProd = process.env.NEXT_PUBLIC_STAGE === 'production'

const BarstoolApp = ({ Component, pageProps }) => {
  const authStore = useStore(AuthStore)
  const navigationStore = useStore(NavigationStore)
  const router = useRouter()

  const [trackerReady, setTrackerReady] = useState(false)
  const [iframeReady, setIframeReady] = useState(false)

  setGlobalLogging(router.query)
  usePageProgress({
    routeWillChange: () => {
      navigationStore.close()
    }
  })

  useEffect(() => {
    authStore.refreshTokenIfNeeded()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    quantcast.loadScript()
    loadPixels()
  }, [])

  useEffect(() => {
    setIframeReady(true)
    window.addEventListener('message', (e) => handleMessage(e, () => setTrackerReady(true)))
  }, [])

  useWhoamiIdentification()

  useEffect(() => {
    if (trackerReady) {
      page()
    }
  }, [trackerReady])

  return (
    <BugsnagProvider>
      <Elements stripe={stripe}>
        <ErrorProvider error={pageProps.error}>
          <Component {...pageProps} />
          <Script
            id='cast'
            strategy='lazyOnload'
            src='https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1'
          />
          <Script
            src={`https://whoami-static.barstoolsports.com/${isProd ? 'prod' : 'dev'}/0.1.9/iife.min.js`}
            strategy='afterInteractive'
          />
          {/* Global Site Code Pixel - Facebook Pixel */}
          <Script
            id='fb-pixel'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
          `
            }}
          />
          {iframeReady && <iframe src='https://barstoolsports.com/bridge.html' style={{ display: 'none' }} />}
        </ErrorProvider>
      </Elements>
    </BugsnagProvider>
  )
}

export default BarstoolApp
