import storage from 'lib/storage'

export class APIResource {
  constructor({ api, resource }) {
    this.baseUrl = api
    this.resource = resource
  }

  /**
   * Makes a request.
   * @param {Object} options
   * @param {String} options.url
   * @param {String=} options.method
   * @param {Object=} options.data
   * @param {Object=} options.params
   * @param {Object=} options.headers
   * @returns {Promise}
   */
  async request({ method = 'get', url: pathname, data, params, headers }) {
    // Construct url with base path
    const url = new URL(this.baseUrl)

    // Safely combine all url path parameters
    url.pathname = url.pathname.replace(/\/$/gi, '') + '/' + pathname.replace(/^\//gi, '')

    // Set url query params
    for (const [key, value] of Object.entries(params ?? [])) {
      if (value === undefined || value === null) continue
      url.searchParams.set(key, value)
    }

    // Make request
    const res = await fetch(url.href, {
      method: method.toUpperCase(),
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: data ? JSON.stringify(data) : undefined
    })

    const xUnionSetAuthToken = res.headers.get('x-union-set-authorization')
    if (xUnionSetAuthToken) {
      storage.authToken = xUnionSetAuthToken
    }

    let json

    // Parse json response, wrap in try/catch to handle error if content-type is not json
    try {
      json = await res.json()
    } catch (err) {
      let message = 'Something went wrong.'
      if (!res.ok) {
        throw { message, status: res.status }
      }
      return message
    }

    // Throw response as an error if we did not receive a 200
    if (!res.ok) {
      throw {
        status: res.status,
        ...json
      }
    }

    return json
  }

  /**
   * GET request.
   * @param {Object} options
   * @param {String} options.url
   * @param {Object=} options.params
   * @returns {Promise}
   */
  async get(options) {
    const data = await this.request({ ...options, method: 'get' })
    return data
  }

  /**
   * POST request.
   * @param {Object} options
   * @param {String} options.url
   * @param {Object=} options.data
   * @returns {Promise}
   */
  async post(options) {
    const data = await this.request({ ...options, method: 'post' })
    return data
  }

  /**
   * PUT request.
   * @param {Object} options
   * @param {String} options.url
   * @param {Object=} options.data
   * @returns {Promise}
   */
  async put(options) {
    const data = await this.request({ ...options, method: 'put' })
    return data
  }

  /**
   * DELETE request.
   * @param {Object} options
   * @param {String} options.url
   * @returns {Promise}
   */
  async delete(options) {
    const data = await this.request({ ...options, method: 'delete' })
    return data
  }

  /**
   * Find resource items
   * @param {Object} params
   * @returns {Promise}
   */
  async find(params) {
    const data = await this.get({ url: `/${this.resource}`, params })
    return data
  }

  /**
   * Find resource item by ID
   * @param {Number} id
   * @returns {Promise}
   */
  async findById(id, params) {
    const data = await this.get({ url: `/${this.resource}/${id}`, params })
    return data
  }

  /**
   * Create resource item
   * @param {Object} item
   * @returns {Promise}
   */
  async create(item) {
    const data = await this.post({ url: `/${this.resource}`, data: item })
    return data
  }

  /**
   * Update resource item
   * @param {Object} item
   * @returns {Promise}
   */
  async update(id, options) {
    const data = await this.put({
      url: `${this.resource}/${id}`,
      data: options
    })
    return data
  }

  /**
   * Update resource item
   * @param {Object} item
   * @returns {Promise}
   */
  async updateMany(updates) {
    const data = await this.put({
      url: `${this.resource}`,
      data: { updates }
    })
    return data
  }

  /**
   * Delete resource item
   * @param {Object} item
   * @returns {Promise}
   */
  async remove(id) {
    const data = await this.delete({
      url: `${this.resource}/${id}`
    })
    return data
  }
}

export class UnionResource extends APIResource {
  constructor(resource) {
    super({ api: process.env.UNION_API, resource })
  }

  request(options) {
    const authToken = storage.authToken || null
    options.headers = {
      Authorization: authToken,
      'X-Union-Site': 'roughnrowdybrawl',
      ...options.headers
    }
    return super.request(options)
  }
}

export class PPVResource extends APIResource {
  constructor(resource) {
    super({ api: process.env.PPV_API, resource })
  }

  request(options) {
    const authToken = storage.authToken || null
    options.headers = {
      Authorization: authToken,
      ...options.headers
    }
    return super.request(options)
  }
}
