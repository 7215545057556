import React from 'react'

const HamburgerMenu = ({ fill = '#f1f1f1' }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby='hamburger-icon'
    >
      <title id='hamburger-icon'>Open The Menu</title>
      <rect y='4' width='18' height='2' fill={fill} />
      <rect y='9' width='18' height='2' fill={fill} />
      <rect y='14' width='18' height='2' fill={fill} />
    </svg>
  )
}

export default HamburgerMenu
