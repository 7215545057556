import { UnionResource } from 'api/resource'

class StoriesApi extends UnionResource {
  constructor() {
    super('stories')
  }
  async find(params) {
    const result = await this.get({ url: '/stories/latest', params })
    return result
  }
  async findVideoSource(id) {
    const result = await this.get({ url: `/stories/${id}/video-source` })
    return result
  }
}

export default StoriesApi
