import { useEffect, useState, useLayoutEffect } from 'react'
import { isServer } from 'utils/server'

export const useClientSide = () => {
  const [ready, setReady] = useState(!isServer)

  useEffect(() => {
    setReady(true)
  }, [])

  return ready
}

// Stolen from: https://github.com/zeit/swr/blob/master/src/use-swr.ts#L41
export const useClientLayoutEffect = isServer ? useEffect : useLayoutEffect
