import { flow, action } from 'mobx'

export const CommonStore = {
  // Must set in subclass
  api: null,

  // Common Properties
  items: [],
  current: null,
  isLoading: false,
  isCreating: false,
  isUpdating: false,

  hydrate: action(function (data) {
    if (!data) return
    Object.assign(this, data)
  }),

  reset: flow(function* () {
    this.items = []
    this.current = null
    this.isLoading = false
    yield
  }),

  find: flow(function* (params) {
    try {
      this.isLoading = true
      const response = yield this.api.find(params)
      this.items = response
      return response
    } finally {
      this.isLoading = false
    }
  }),

  findNext: flow(function* (params) {
    try {
      this.isLoading = true
      const items = yield this.api.find(params)
      this.items.push(...items)
      return items
    } finally {
      this.isLoading = false
    }
  }),

  findById: flow(function* (id) {
    try {
      this.isLoading = true
      const response = yield this.api.findById(id)
      this.current = response
      return response
    } finally {
      this.isLoading = false
    }
  }),

  create: flow(function* (options) {
    try {
      this.isCreating = true
      const response = yield this.api.create(options)
      this.current = response
      return response
    } finally {
      this.isCreating = false
    }
  }),

  update: flow(function* (id, options) {
    try {
      this.isUpdating = true
      const response = yield this.api.update(id, options)
      this.current = response
      this._merge([this.current])
      return response
    } finally {
      this.isUpdating = false
    }
  }),

  updateMany: flow(function* (options) {
    try {
      this.isUpdating = true
      const response = yield this.api.updateMany(options)
      this._merge(response)
      return response
    } finally {
      this.isUpdating = false
    }
  }),

  _merge: flow(function* (updatedItems) {
    this.items = this.items.map((item) => {
      for (const updatedItem of updatedItems) {
        if (updatedItem.id === item.id) return updatedItem
      }
      return item
    })
    yield
  })
}

export default CommonStore
