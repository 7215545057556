import { observable, flow, action } from 'mobx'
import { userApi, purchasesApi } from 'api'
import { CommonStore } from 'stores/common'
import storage from 'lib/storage'

export const UserStore = () => {
  const findMe = flow(function* ({ forceFetch = true } = {}) {
    const localUserData = storage.userAccount
    if (forceFetch || !localUserData || localUserData.id !== storage.userId) {
      try {
        const response = yield this.findById('me')
        storage.userAccount = response
        this.current = response
        return response
      } catch (err) {
        if (localUserData) {
          storage.userAccount = null
        }
        throw err
      }
    }
    this.current = localUserData
    return localUserData
  })

  const setCurrent = action(function (user) {
    this.current = user
    storage.userAccount = user
  })

  const update = flow(function* (id, data) {
    try {
      this.isUpdating = true
      const response = yield this.api.update(id, data)
      this.current = response
      storage.userAccount = response
      return response
    } finally {
      this.isUpdating = false
    }
  })

  const updatePassword = flow(function* (data) {
    const response = yield this.api.updatePassword(data)
    return response
  })

  const getStripeCustomer = flow(function* () {
    try {
      this.isLoadingCustomer = true
      const response = yield this.api.getStripeCustomer(storage.userId)
      this.customer = response
      return response
    } finally {
      this.isLoadingCustomer = false
    }
  })

  const addPaymentMethod = flow(function* (token) {
    if (!storage.userId) {
      throw new Error('Must be logged in to add a payment method')
    }
    this.isUpdating = true
    const response = yield this.api.updateStripeCustomer(storage.userId, { source: token })
    this.customer = response
    return response
  })

  const getPurchases = flow(function* (params = {}) {
    try {
      const response = yield purchasesApi.find(params)
      this.purchases = response
      return response
    } catch (err) {
      console.error(err)
      throw err
    }
  })

  return observable({
    ...CommonStore,
    api: userApi,
    customer: null,
    purchases: [],
    isLoadingCustomer: false,
    findMe,
    update,
    updatePassword,
    getStripeCustomer,
    addPaymentMethod,
    getPurchases,
    setCurrent
  })
}

UserStore.cacheKey = 'UserStore'

export default UserStore
