import React from 'react'
import Link from 'next/link'

import CCPALink from 'components/CCPALink'
import CaliforniaNoticesLink from 'components/CaliforniaNoticesLink'
import AllyantLogo from 'icons/AllyantLogo'

import THEME from 'utils/theme'

const SiteFooter = ({
  linkToggles = {
    help: true,
    termsOfUse: true,
    privacyPolicy: true,
    subscriptionTerms: true,
    ccpa: true,
    californiaNotices: true,
    txtMsgTC: true
  },
  backgroundColor,
  foregroundColor,
  fontSize
}) => {
  return (
    <>
      <footer className='minimalFooter'>
        <p>
          &copy;{new Date().getFullYear()} Barstool Brawl, LLC
          <br />
          {linkToggles?.help && (
            <a href='https://help.roughnrowdybrawl.com/' rel='noopener noreferrer' target='_blank'>
              Help
            </a>
          )}
          {linkToggles?.termsOfUse && (
            <Link href='/terms-of-use' legacyBehavior>
              <a>Terms of Use</a>
            </Link>
          )}
          {linkToggles?.privacyPolicy && (
            <Link href='/privacy-policy' legacyBehavior>
              <a>Privacy Policy</a>
            </Link>
          )}
          {linkToggles?.ccpa && (
            <CCPALink
              style={{
                display: 'inline-block',
                color: foregroundColor || THEME.COLORS.GRAY,
                fontWeight: 500,
                margin: '0.25rem',
                textDecoration: 'none',
                cursor: 'pointer',
                fontSize: fontSize || '12px'
              }}
            />
          )}
          {linkToggles?.californiaNotices && (
            <CaliforniaNoticesLink
              style={{
                display: 'inline-block',
                color: foregroundColor || THEME.COLORS.GRAY,
                fontWeight: 500,
                margin: '0.25rem',
                textDecoration: 'none',
                cursor: 'pointer',
                fontSize: fontSize || '12px'
              }}
            />
          )}
          {linkToggles?.txtMsgTC && (
            <a
              href='https://barstool-sports-2.myklpages.com/p/mobile-terms-of-service'
              target='_blank'
              rel='noopener noreferrer'
            >
              Text Messaging Terms & Conditions
            </a>
          )}
        </p>
        <a href='http://www.allyant.com' target='_blank' rel='noopener noreferrer'>
          <AllyantLogo />
        </a>
      </footer>
      <style jsx>{`
        .svgIcon {
          height: 2.5rem;
          width: 2.5rem;
        }
        .minimalFooter {
          background: ${backgroundColor || '#f7f7f7'};
          position: relative;
          text-align: center;
          display: block;
          font-family: inherit;
          padding: 2rem 0;
        }
        .minimalFooter p {
          color: ${foregroundColor || THEME.COLORS.GRAY};
          margin-top: 0.5rem;
          letter-spacing: normal;
          font-size: ${fontSize || '12px'};
        }
        .minimalFooter p:last-child {
          margin-bottom: 0;
        }
        .minimalFooter a {
          display: inline-block;
          color: ${foregroundColor || THEME.COLORS.GRAY};
          font-weight: 500;
          margin: 0.25rem;
          text-decoration: none;
          cursor: pointer;
          font-size: ${fontSize || '12px'};
        }
        .minimalFooter a .allyant-logo {
          margin-top: 0.25rem;
        }
      `}</style>
    </>
  )
}

export default SiteFooter
