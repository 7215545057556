import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import UserStore from 'stores/user'
import { isServer } from 'utils/server'
import { withStore } from 'hooks/store'

let ErrorBoundary = null

const bugsnagOptions = {
  apiKey: process.env.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
  onError: (event) => {
    const { current } = withStore(UserStore)

    // Don't send bug reports for logged out users to limit error volume
    if (!current) return false

    // Set user data on the error event
    event.setUser(current.id.toString(), current.email, current.full_name)

    // Send the event to bugnsag
    return true
  }
}

export const BugsnagProvider = ({ children }) => {
  if (isServer) {
    return children
  }

  if (!ErrorBoundary) {
    Bugsnag.start(bugsnagOptions)
    ErrorBoundary = Bugsnag.getPlugin('react')
  }

  return <ErrorBoundary>{children}</ErrorBoundary>
}

export const catchError = (...args) => Bugsnag.notify(...args)
