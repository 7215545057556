import React from 'react'
import Link from 'next/link'

const NavLink = ({ item }) => {
  const classNames = ['siteNavigation__link']
  if (item.primary) {
    classNames.push('siteNavigation__link--primary')
  }
  if (item.mobile) {
    classNames.push('siteNavigation__link--mobile')
  }
  if (item.href === '/events') {
    classNames.push('siteNavigation__upcoming-events')
  }

  if (item.external) {
    return (
      <a
        itemProp='url'
        href={item.href}
        target='_blank'
        rel='noopener noreferrer'
        className={classNames.join(' ').trim()}
      >
        {item.title}
      </a>
    )
  }

  return (
    <Link href={item.href} itemProp='url' className={classNames.join(' ').trim()}>
      {item.title}
    </Link>
  )
}

export default NavLink
