const COLORS = {
  PRIMARY: '#EB3E3E',
  PRIMARY_DARKENED: '#E92727',
  SECONDARY: '#141E30',
  YELLOW: '#F0C030',
  GOLD: '#E8A207',
  GRAY: '#757575',
  LIGHTGRAY: '#D8D8D8',
  LIGHTERGRAY: '#E3E3E3',
  LIGHTESTGRAY: '#F9F9F9',
  TEXTCOLOR: '#222222',
  LINKCOLOR: '#377DC4',
  SUCCESS: '#7ED321',
  GREEN: '#00B353',
  ERROR: '#EB3E3E',
  BACKGROUND_GRAY: '#f7f7f7'
}

const BREAKPOINTS = {
  XS: 600,
  SM: 768,
  MD: 960,
  LG: 1024,
  XL: 1200
}

const FONTS = {
  BEBAS: 'Bebas Neue, Impact, -apple-system, Segoe UI, Arial, sans-serif',
  HELVETICA: 'Helvetica Neue, Helvetica, -apple-system, Segoe UI, Arial, sans-serif'
}

const BOX_SHADOW = {
  DEFAULT: '0 0 3px 0 rgba(51, 51, 51, 0.18)'
}

const THEME = {
  COLORS,
  BREAKPOINTS,
  FONTS,
  BOX_SHADOW
}

export default THEME
