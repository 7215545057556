import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import THEME from 'utils/theme'
import { isServer } from 'utils/server'

const MediaQuerySSR = ({ children, ...props }) => {
  const mediaOptions = isServer ? { width: THEME.BREAKPOINTS.XL } : undefined
  const shouldRender = useMediaQuery(props, mediaOptions)

  return shouldRender && children ? children : null
}

MediaQuerySSR.propTypes = {
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number
}

export default MediaQuerySSR
