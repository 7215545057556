import { event } from './event'

export const videoSource = {
  status: 'live',
  name: 'RNR 12',
  type: 'rough_n_rowdy',
  thumbnail:
    'https://chumley.barstoolsports.com/union/2020/10/01/upcoming-thumbnail.a9ed65f6.png?crop=0.96%2C0.96%2Cx0.02%2Cy0.01%2Csafe',
  aspect_ratio: '16:9',
  id: event.id,
  primary_source: {
    src: 'https://stream.mux.com/BVSvAFpL0202vgNKO5piKBe5aL0100szDVyR.m3u8',
    type: 'application/x-mpegURL'
  },
  alternate_sources: [
    {
      src: 'https://stream.mux.com/BVSvAFpL0202vgNKO5piKBe5aL0100szDVyR.m3u8',
      type: 'application/x-mpegURL'
    },
    {
      src: 'https://stream.mux.com/BVSvAFpL0202vgNKO5piKBe5aL0100szDVyR.m3u8',
      type: 'application/x-mpegURL'
    }
  ]
}
