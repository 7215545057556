export const playlist = {
  id: 27,
  title: 'Testing GIF to MP4 thumbnails in right rail (updated)',
  slug: 'testing-gif-to-mp4-thumbnails-in-right-rail-updated-27',
  brand_id: 119,
  category_id: '98',
  sort: 0,
  is_membership: 0,
  is_archive: 0,
  is_featured: 0,
  updated_at: '2021-01-06T18:54:10.000Z',
  created_at: '2020-12-01T14:41:05.000Z',
  is_active: 1,
  thumbnail: {
    type: 'small',
    location: 'https://chumley.barstoolsports.com/union/2020/09/19/',
    images: {
      small: 'marvin-heilemann-hhwxgGPWg-A-unsplash.a47acfea.jpg?crop=0.35%2C0.30%2Cx0.31%2Cy0.35%2Csafe&width=560',
      medium: 'marvin-heilemann-hhwxgGPWg-A-unsplash.a47acfea.jpg?crop=0.35%2C0.30%2Cx0.31%2Cy0.35%2Csafe&width=560',
      large: 'marvin-heilemann-hhwxgGPWg-A-unsplash.a47acfea.jpg?crop=0.35%2C0.30%2Cx0.31%2Cy0.35%2Csafe&width=560'
    },
    desktop:
      'https://chumley.barstoolsports.com/union/2020/09/19/marvin-heilemann-hhwxgGPWg-A-unsplash.a47acfea.jpg?crop=0.35%2C0.30%2Cx0.31%2Cy0.35%2Csafe&width=560',
    raw:
      'https://chumley.barstoolsports.com/union/2020/09/19/marvin-heilemann-hhwxgGPWg-A-unsplash.a47acfea.jpg?crop=0.35%2C0.30%2Cx0.31%2Cy0.35%2Csafe&width=560',
    raw_desktop:
      'https://chumley.barstoolsports.com/union/2020/09/19/marvin-heilemann-hhwxgGPWg-A-unsplash.a47acfea.jpg?crop=0.35%2C0.30%2Cx0.31%2Cy0.35%2Csafe&width=560',
    featured: null,
    raw_featured: null
  },
  access_level: 0,
  site_barstoolsports: 1,
  site_barstoolbets: 0,
  thumbnail_default: {
    type: 'small',
    location: 'https://chumley.barstoolsports.com/union/defaults/',
    images: {
      small: 'thumbnail.jpg',
      medium: 'thumbnail.jpg',
      large: 'thumbnail.jpg'
    },
    desktop: 'https://chumley.barstoolsports.com/union/defaults/thumbnail.jpg?crop=4%3A3&width=560',
    raw: 'https://chumley.barstoolsports.com/union/defaults/thumbnail.jpg',
    raw_desktop: 'https://chumley.barstoolsports.com/union/defaults/thumbnail.jpg?crop=4%3A3&width=560',
    featured: null,
    raw_featured: null
  },
  story_count: 1,
  stories: [
    {
      id: 1358141,
      brand_id: null,
      title: 'test bets video',
      type: 'barstool_original',
      url: 'https://www.barstoolsports.dev/video/1358141/test-bets-video',
      branch_url: 'https://bars.tl/1358141',
      thumbnail: {
        type: 'large',
        location: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/12/30/',
        images: {
          small: 'e27bb39d7be2acd1.png?crop=16%3A9%2Csmart',
          medium: 'e27bb39d7be2acd1.png?crop=16%3A9%2Csmart',
          large: 'e27bb39d7be2acd1.png?crop=16%3A9%2Csmart'
        },
        desktop: 'https://chumley.barstoolsports.com/union/defaults/thumbnail.jpg?crop=4%3A3&width=560',
        raw:
          'https://chumley.barstoolsports.com/wp-content/uploads/2019/12/30/e27bb39d7be2acd1.png?crop=16%3A9%2Csmart',
        raw_desktop: 'https://chumley.barstoolsports.com/union/defaults/thumbnail.jpg',
        featured: null,
        raw_featured: null
      },
      nsfw: false,
      comment_count: 0,
      comment_status_open: true,
      category: [
        {
          id: '98',
          slug: 'newyork',
          name: 'New York'
        }
      ],
      tag: 'Barstool Original',
      tags: [],
      author: {
        name: 'Thomas Rademaker',
        avatar: null,
        author_url: '/bio/103685/thomas-rademaker',
        id: 103685,
        has_notifications: false,
        is_active: false
      },
      post_type_meta: {
        standard_post: {},
        barstool_original: {
          id: 'e879Dgqq5X5BFLu7amk8Qt',
          duration: 136012,
          type: 'barstool',
          pre_roll_disabled: false,
          image:
            'https://chumley.barstoolsports.com/wp-content/uploads/2019/12/30/e27bb39d7be2acd1.png?crop=16%3A9%2Csmart',
          show: {
            id: '98',
            title: 'New York',
            slug: 'newyork',
            image: null
          },
          site_iframe_url:
            'https://union-staging.barstoolsports.com/v2/video/standard/a266511d-40a8-4b16-8b18-af47518758b4/1358141'
        },
        podcast: null,
        gallery: null,
        live: null,
        fundraiser: null
      },
      product: {
        id: null
      },
      campaign: null,
      viewability: 0,
      is_pinned: 0,
      is_featured: 0,
      is_published: 1,
      is_hidden: 0,
      is_show_guide: 0,
      is_membership: 0,
      is_archive: 0,
      is_search_indexed: 1,
      gated_product_id: null,
      access_level: 0,
      ads_disabled: 0,
      is_featured_section: 0,
      blocker_description: '',
      is_instant_article: 0,
      is_gold: false,
      date: '2020-11-18T22:32:50.000Z',
      slug: 'test-bets-video',
      hidden_tags: [],
      sites: {
        barstoolsports: false,
        barstoolbets: true
      },
      urls: {
        barstoolsports: 'https://www.barstoolsports.dev/video/1358141/test-bets-video',
        barstoolbets: 'https://www-staging.barstoolbets.com/videos/1358141/test-bets-video',
        canonical: 'https://www-staging.barstoolbets.com/videos/1358141/test-bets-video'
      },
      sharing: {
        facebook: {
          title: 'test bets video',
          url: 'https://www.barstoolsports.dev/video/1358141/test-bets-video'
        },
        twitter: {
          title: 'test bets video',
          url: 'https://www.barstoolsports.dev/video/1358141/test-bets-video'
        },
        sms: {
          title: 'test bets video',
          url: 'https://www.barstoolsports.dev/video/1358141/test-bets-video'
        },
        email: {
          title: 'test bets video',
          url: 'https://www.barstoolsports.dev/video/1358141/test-bets-video'
        }
      },
      updated_at: '2020-11-18T22:33:22.000Z'
    }
  ]
}
