import { useEffect, useReducer } from 'react'

import { useRouter } from 'next/router'
import { withFlow } from 'utils/flow'
import { useStore, useInterval } from 'hooks'
import { EventsStore } from 'stores/events'
import { AuthStore } from 'stores/auth'

const DEFAULT_STATE = { status: 'IDLE', videoSource: null, error: null }

const reducer = (state, action) => {
  switch (action.type) {
    case 'VIDEO_SOURCE_REQUESTED': {
      return {
        ...state,
        status: 'PENDING',
        videoSource: null,
        error: null
      }
    }
    case 'VIDEO_SOURCE_RECEIVED': {
      return {
        ...state,
        status: 'RESOLVED',
        videoSource: action.payload,
        error: null
      }
    }
    case 'VIDEO_SOURCE_ERROR': {
      return {
        ...state,
        status: 'ERROR',
        videoSource: null,
        error: action.payload
      }
    }
    case 'RESET': {
      return DEFAULT_STATE
    }
    default: {
      return state
    }
  }
}

export const useEventVideoSource = () => {
  const router = useRouter()
  const authStore = useStore(AuthStore)
  const eventsStore = useStore(EventsStore)
  const event = eventsStore.current
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)

  useInterval(
    () => {
      refetchEvent(event.id)
    },
    60000,
    [event.id]
  )

  function refetchEvent(eventId) {
    eventsStore
      .findById(eventId)
      .then(() => {
        event.status === 'live' && refetchVideoSource(eventId)
      })
      .catch((err) => {
        // redirect when event 404's, such as when event has been archived, otherwise
        // users with open tabs will continue to hammer /events/:id
        if (err.status === 404) {
          router.push('/')
        }
      })
  }

  async function refetchVideoSource(eventId) {
    try {
      const videoSource = await eventsStore.readVideoSource(eventId)
      dispatch({ type: 'VIDEO_SOURCE_RECEIVED', payload: videoSource })
    } catch (err) {
      if (err.message !== 'FLOW_CANCELLED') {
        dispatch({ type: 'VIDEO_SOURCE_ERROR', payload: err })
      }
    }
  }

  const hasEventAccess = authStore.hasEventAccess(event.id)

  useEffect(() => {
    let op
    if (event.id) {
      if ((event.status === 'live' || event.status === 'on_demand') && !state.videoSource) {
        dispatch({ type: 'VIDEO_SOURCE_REQUESTED' })
        op = withFlow(eventsStore.readVideoSource(event.id))
        op.then((response) => {
          dispatch({ type: 'VIDEO_SOURCE_RECEIVED', payload: response })
        }).catch((err) => {
          // TODO: figure out why this is needed to get tests passing
          if (err.message !== 'FLOW_CANCELLED') {
            dispatch({ type: 'VIDEO_SOURCE_ERROR', payload: err })
          }
        })
        return () => {
          if (op) {
            op.cancel()
          }
        }
      } else if (state.videoSource && (event.status === 'upcoming' || event.status === 'ended')) {
        dispatch({ type: 'RESET' })
      }
    }
  }, [event.id, event.status, state.videoSource, hasEventAccess, eventsStore])

  return state
}
