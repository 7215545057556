import ExternalScriptLoader from 'lib/external-script-loader'
import { withLogger } from 'lib/logger'

const logger = withLogger('AdwordsPixel')

class AdWords extends ExternalScriptLoader {
  scriptUrl = `https://www.googletagmanager.com/gtag/js?id=${process.env.ADWORDS_ID}`
  options = { defer: true }

  init() {
    window.dataLayer = window.dataLayer || []
    window.gtag = function () {
      window.dataLayer.push(arguments)
    }
    window.gtag('js', new Date())
    window.gtag('config', process.env.ADWORDS_ID)
  }
  async sendPurchase(purchase) {
    try {
      await this.loadScript()
      window.gtag('event', 'conversion', {
        send_to: `${process.env.ADWORDS_ID}/Tg9vCO3WpuIBEJz_p_AC`,
        value: purchase.spent,
        currency: 'USD',
        transaction_id: purchase.id
      })
    } catch (err) {
      logger.error(err)
    }
  }
}

export default new AdWords()
