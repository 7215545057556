import { PPVResource } from 'api/resource'

class EventsAuthApi extends PPVResource {
  constructor() {
    super('auth')
  }

  async refresh() {
    const result = await this.post({ url: `/${this.resource}/refresh` })
    return result
  }

  async initBraintree() {
    return await this.post({ url: `/${this.resource}/braintree-client` })
  }
}

export default EventsAuthApi
