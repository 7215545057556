import React from 'react'
import Head from 'components/Head'
import SiteLayout from 'components/SiteLayout'
import NotFound from 'components/NotFound'
import { usePageView } from 'hooks'

const defaultStatusCode = 500
const defaultErrorMessage = 'Something went wrong.'

const ErrorPage = ({ statusCode, errorMessage }) => {
  const errorOptions = {
    statusCode: statusCode || defaultStatusCode,
    errorMessage: errorMessage || defaultErrorMessage
  }

  usePageView()

  return (
    <SiteLayout>
      <Head title='Error | Rough N’ Rowdy' noIndexNoFollow />
      <div className='errorContainer'>
        <NotFound {...errorOptions} />
      </div>
      <style global jsx>
        {`
          .layout__main {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        `}
      </style>
      <style jsx>
        {`
          .errorContainer {
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .errorContainer h1 {
            font-size: 72px;
          }
          .errorContainer p {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
          }
        `}
      </style>
    </SiteLayout>
  )
}

export default ErrorPage
