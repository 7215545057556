import AdWordsPixel from './adwords'
import CriteoPixel from './criteo'
import { pageview as facebookPixelPageView } from './facebook'

export const loadPixels = () => {
  AdWordsPixel.loadScript()
  CriteoPixel.loadScript()
}

export const pageView = () => {
  facebookPixelPageView()
}

export default loadPixels
