import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useStore } from 'hooks'
import { AuthStore } from 'stores/auth'
import { EventsStore } from 'stores/events'

export const useEventAuth = () => {
  const router = useRouter()
  const authStore = useStore(AuthStore)
  const eventsStore = useStore(EventsStore)

  const event = eventsStore.current

  useEffect(() => {
    if (!authStore.loggedIn) {
      if (authStore.error) {
        router.push({
          pathname: '/account/error',
          query: {
            type: authStore.error
          }
        })
      } else {
        router.push({
          pathname: '/account/signin',
          query: {
            redirectHref: '/events/[id]/purchase',
            redirectAs: `/events/${event.id}/purchase`
          }
        })
      }
    }

    if (authStore.loggedIn) {
      const refreshEventAccess = async () => await authStore.refreshToken()

      if (!authStore.hasEventAccess(event.id)) {
        refreshEventAccess().then(() => {
          if (!authStore.hasEventAccess(event.id)) {
            router.push('/events/[id]/purchase', `/events/${event.id}/purchase`)
          }
        })
      }
    }
  }, [authStore.loggedIn, authStore.error, event.id])
}
