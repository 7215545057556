import { PPVResource } from 'api/resource'

class EventsApi extends PPVResource {
  constructor() {
    super('events')
  }

  async purchaseEvent(id, data) {
    return await this.post({ url: `${this.resource}/${id}/purchase`, data })
  }

  async promoPurchase(data) {
    return await this.post({ url: '/purchases', data })
  }

  async readVideoSource(id) {
    return await this.get({
      url: `/${this.resource}/${id}/video-source`
    })
  }
}

export default EventsApi
