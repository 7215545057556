import { TYPE_FORMS } from 'constants/typeform'

export const event = {
  series: {
    name: "Rough N' Rowdy",
    abbreviation: 'RnR',
    episode: '12'
  },
  status: 'live',
  name: 'RNR 12',
  type: 'rough_n_rowdy',
  created_at: '2020-08-19T01:25:10.703Z',
  updated_at: '2020-10-06T21:33:12.730Z',
  description:
    "What better way to spend an otherwise boring Thursday night than with ring girls, knockouts, plus El Pres & Big Cat on the call! This RnR may not be live but it's still sure to memorable with Barstool watching along all night starting at 9pm.",
  metadata: {
    location: {
      address1: '777',
      address2: '',
      city: 'Virginia Beach',
      state: 'VA',
      zip: '23450',
      country: 'US'
    },
    promo_poster_url: 'https://chumley.barstoolsports.com/union/2020/08/18/rnr-11-flyer.8f77b9c7.jpg',
    promo_video_source: {
      src: 'https://stream.mux.com/CjzrVrSUOiKyj02k02RyXa9LI4hRLrKiY1.m3u8',
      type: 'application/x-mpegURL'
    },
    logo_url: 'https://chumley.barstoolsports.com/union/2020/08/18/rnr-12-logo.46c4f999.svg',
    fighter_signup_url: TYPE_FORMS.fighter_signup_url,
    ring_girl_signup_url: TYPE_FORMS.ring_girl_signup_url,
    tickets: [
      {
        url: 'https://www1.ticketmaster.com/event/0100573BDE536C26',
        date: '2020-10-23T15:41:53.453Z',
        thumbnail: 'https://chumley.barstoolsports.com/union/2020/08/18/rnr-12-logo.46c4f999.svg',
        is_available: true
      }
    ]
  },
  date: '2020-10-23T23:00:00.000Z',
  price: 19.99,
  thumbnail:
    'https://chumley.barstoolsports.com/union/2020/10/01/upcoming-thumbnail.a9ed65f6.png?crop=0.96%2C0.96%2Cx0.02%2Cy0.01%2Csafe',
  aspect_ratio: '16:9',
  number: 12,
  id: '0976f637'
}
