import React from 'react'
import { observer } from 'mobx-react-lite'
import THEME from 'utils/theme'
import { useStore } from 'hooks'
import { NotificationStore } from 'stores/notification'

const NotificationMessage = ({ children }) => {
  return (
    <>
      <div className='notification__message'>{children}</div>
      <style jsx>{`
        .notification__message {
          font-size: 14px;
          font-weight: bold;
        }
      `}</style>
    </>
  )
}

const NotificationClose = ({ handleClose }) => {
  return (
    <>
      <div className='notification__close' onClick={handleClose}>
        &#215;
      </div>
      <style jsx>{`
        .notification__close {
          pointer-events: auto;
          font-size: 20px;
          margin-left: 20px;
        }
        .notification__close :hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      `}</style>
    </>
  )
}

export const Notification = observer(({ notification }) => {
  const notificationStore = useStore(NotificationStore)
  const handleClose = () => {
    notificationStore.removeNotification(notification.id)
  }
  return (
    <>
      <div role='alert' className={`notification notification--${notification.type}`}>
        <NotificationMessage>{notification.message}</NotificationMessage>
        <NotificationClose handleClose={handleClose} />
      </div>
      <style jsx>{`
        .notification {
          min-width: 288px;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          margin-top: 8px;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 16px;
          padding-bottom: 16px;
          color: white;
          background-color: rgb(23, 78, 182);
          box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 16px;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-property: all;
        }
        .notification--info {
          background-color: rgb(23, 78, 182);
        }
        .notification--success {
          background-color: ${THEME.COLORS.SUCCESS};
        }
        .notification--warning {
          background-color: rgb(180, 84, 11);
        }
        .notification--error {
          background-color: rgb(174, 55, 42);
        }
      `}</style>
    </>
  )
})

const NotificationContainer = () => {
  const notificationStore = useStore(NotificationStore)
  return (
    <>
      <div className='notification-container'>
        {notificationStore.items.map((item) => (
          <Notification key={item.id} notification={item} />
        ))}
      </div>
      <style jsx>{`
        .notification-container {
          position: fixed;
          bottom: 10px;
          pointer-events: none;
          display: flex;
          align-items: center;
          flex-direction: column-reverse;
          width: 100%;
        }
      `}</style>
    </>
  )
}

export default observer(NotificationContainer)
