import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'hooks'
import { NavigationStore } from 'stores/navigation'
import { siteHeaderEvents } from 'lib/ga'
import THEME from 'utils/theme'
import NavLink from 'components/NavLink'

const SiteNavigation = ({ eventNavItems = [], vertical = false, component: Component = NavLink }) => {
  const navigationStore = useStore(NavigationStore)

  return (
    <nav className='siteNavigation'>
      <ul itemScope itemType='http://www.schema.org/SiteNavigationElement' className='siteNavigation__nav'>
        {[...eventNavItems, ...navigationStore.navigationItems].map((item, idx) => {
          if (item.hidden) return null
          if (!item.href) return null
          return (
            <li
              key={idx}
              itemProp='name'
              onClick={() => {
                siteHeaderEvents.navLink(item.title)
              }}
            >
              <Component item={item} />
            </li>
          )
        })}
      </ul>
      <style jsx>{`
        .siteNavigation {
          display: block;
          padding: 0 5px;
        }
        .siteNavigation__nav {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: ${vertical ? 'column' : 'row'};
          align-items: ${vertical ? 'flex-start' : 'baseline'};
          overflow-x: auto;
          overflow-y: hidden;
          list-style: none;
          margin: 0;
          padding: 0;
          height: ${vertical ? '100%' : '45px'};
          font-size: 14px;
        }
        .siteNavigation__nav li {
          height: 100%;
          bottom: 0;
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .siteNavigation__nav {
            font-size: 20px;
          }
        }
        @media (min-width: 1136px) {
          .siteNavigation__nav {
            overflow-x: visible;
            align-items: center;
          }
          .siteNavigation__nav > li > a.featured {
            font-size: 24px;
          }
        }
      `}</style>
    </nav>
  )
}

export default observer(SiteNavigation)
