import React from 'react'
import Link from 'next/link'

import SiteNavigation from 'components/SiteNavigation'

import { useStore } from 'hooks'
import { siteHeaderEvents } from 'lib/ga'
import AuthStore from 'stores/auth'

const SiteNavigationMobile = ({ eventNavItems, isOpen, toggleMobileNavOpen }) => {
  const authStore = useStore(AuthStore)
  const handleLogout = async () => {
    authStore.logout()
    toggleMobileNavOpen()
    window.location = '/'
  }
  return (
    <>
      <div className='mobileNav'>
        <div className='mobileNav__nav'>
          <SiteNavigation eventNavItems={eventNavItems.map((item) => ({ ...item, mobile: true }))} vertical />
          {!process.env.WINTERIZED && (
            <nav className='siteNavigation'>
              <ul itemScope itemType='http://www.schema.org/SiteNavigationElement' className='siteNavigation__nav'>
                <li itemProp='name'>
                  <Link href={authStore.loggedIn ? '/account' : '/account/signin'} legacyBehavior>
                    <a
                      tabIndex={isOpen ? '0' : '-1'}
                      className='siteNavigation__link siteNavigation__link--mobile'
                      onClick={() => {
                        if (authStore.loggedIn) siteHeaderEvents.account()
                      }}
                    >
                      <span className='iconSubtitle'>{authStore.loggedIn ? 'Account' : 'Sign In'}</span>
                    </a>
                  </Link>
                </li>
                {authStore.loggedIn && (
                  <li itemProp='name'>
                    <a
                      itemProp='url'
                      target='_blank'
                      onClick={handleLogout}
                      rel='noopener noreferrer'
                      className='siteNavigation__link siteNavigation__link--mobile siteNavigation__link--logout'
                      tabIndex={isOpen ? '0' : '-1'}
                    >
                      Log Out
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          )}
        </div>
      </div>
      <style jsx>{`
        .siteNavigation {
          display: block;
          padding: 0 5px;
        }
        .siteNavigation__nav {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          align-items: flex-start;
          overflow-x: auto;
          overflow-y: hidden;
          list-style: none;
          margin: 0;
          padding: 0;
          height: 100%;
          font-size: 20px;
        }
        .mobileNav {
          position: absolute;
          left: ${isOpen ? 0 : '100%'};
          top: 100%;
          z-index: 2;
          width: 100%;
          padding: 16px 6px;
          background-color: black;
          display: flex;
          flex-direction: column;
        }
        .mobileNav__close {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 15px;
        }
        .mobileNav__nav {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
        }
        .mobileNav__navBottom {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      `}</style>
    </>
  )
}

export default SiteNavigationMobile
