import { withLogger } from 'lib/logger'

const logger = withLogger('ExternalScript')

/**
 * @method loadDom
 * @return {Promise}
 */
export const loadDom = (readyStates = ['complete']) => {
  return new Promise((resolve) => {
    if (readyStates.includes(document.readyState)) {
      resolve()
    } else {
      window.addEventListener('load', resolve)
    }
  })
}

/**
 * @method loadScript
 * @param {String} src
 * @param {Object} attrs
 * @return {Promise}
 */
export const loadScript = (src, { async = true, defer = false, ...attrs } = {}) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.async = async && !defer
    script.defer = defer

    for (const [k, v] of Object.entries(attrs || {})) {
      script.setAttribute(k, v)
    }

    script.onload = () => {
      script.onerror = script.onload = null
      resolve(script)
    }

    script.onerror = () => {
      script.onerror = script.onload = null
      reject(new Error(`Failed to load ${src}`))
    }

    logger.log('loadScript():', src)
    document.body.append(script)
  })
}
