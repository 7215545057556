import { customer } from './customer'
import { faker } from '@faker-js/faker'

export const user = {
  id: 123456,
  user_id: 123456,
  type: 0,
  email: 'test@barstoolsports.com',
  username: 'test',
  full_name: 'Test User',
  first_name: 'Test',
  last_name: 'User',
  phone_number: '1234567890',
  banned: false,
  verified: false,
  newsletter: false,
  email_verified: true,
  stripe_customer: customer.id,
  author: {},
  meta: {
    address: faker.location.streetAddress(),
    address2: faker.location.secondaryAddress(),
    city: faker.location.city(),
    state: faker.location.state(),
    zip_code: faker.location.zipCode(),
    phone: '1234567890',
    twitter: '',
    snapchat: '',
    instagram: ''
  }
}
