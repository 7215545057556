import { loadDom, loadScript } from 'lib/load-script'
import { isServer } from 'utils/server'

class ExternalScriptLoader {
  loaded = false
  loadingPromise = null
  options = { async: true }
  readyStates = ['complete']

  init() {
    // override in subclass
  }

  async loadScript() {
    if (!this.scriptUrl) {
      throw new Error('Child class must implement scriptUrl')
    }

    if (isServer) {
      throw new Error(
        `Attempted to load an external script on the server. Make sure you call loadScript inside a useEffect hook: ${this.scriptUrl}`
      )
    }

    if (this.loaded) {
      return
    }

    if (this.loadingPromise) {
      return this.loadingPromise
    }

    this.init()

    this.loadingPromise = loadDom(this.readyStates)
      .then(() => loadScript(this.scriptUrl, this.options))
      .then(() => (this.loaded = true))
      .catch((err) => console.error('[ExternalScriptLoader] Failed:', this.scriptUrl, err))
      .finally(() => (this.loadingPromise = null))

    return this.loadingPromise
  }
}

export default ExternalScriptLoader
