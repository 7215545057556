import { v4 as uuid } from 'uuid'
import { user } from './user'

import { token } from './token'

const sessionId = uuid()

export const login = {
  session_id: sessionId,
  token,
  user,
  username: user.username,
  user_id: user.id,
  token_type: 'bearer',
  success: true,
  sessions: [sessionId]
}
