import ExternalScriptLoader from './external-script-loader'

class Intercom extends ExternalScriptLoader {
  scriptUrl = `https://widget.intercom.io/widget/${process.env.INTERCOM_APP_ID}`
  options = { async: true }

  async load(options) {
    window.intercomSettings = options
    await this.loadScript()
    if (!window.Intercom) return
    if (window.Intercom.booted) {
      window.Intercom('update')
    }
  }
}

export default new Intercom()
