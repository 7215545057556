export const config = {
  videos: {
    ads: {
      index_page_banner_ad: {
        src: 'https://chumley.barstoolsports.com/union/2020/10/21/2000x515-2-1.2a5d330a.jpg?crop=1000%3A258%2Csmart',
        destination: 'https://playbarstool.com/'
      },
      view_page_banner_ad: {
        src: 'https://chumley.barstoolsports.com/union/2020/10/21/2000x515-2-1.2a5d330a.jpg?crop=1000%3A258%2Csmart',
        destination: 'https://playbarstool.com/'
      },
      view_page_list_ad: {
        src:
          'https://chumley.barstoolsports.com/union/2020/10/21/playbarstool-banner-mobile.d28b5ec5.jpg?canvas=500%3A500&bg-color=000000',
        destination: 'https://playbarstool.com/'
      }
    },
    featured_story_id: 1358216,
    feeds: [
      {
        type: 'playlist',
        id: 18
      },
      {
        type: 'playlist',
        id: 27
      },
      {
        type: 'playlist',
        id: 24
      },
      {
        type: 'playlist',
        id: 28
      }
    ]
  }
}
