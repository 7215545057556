import { UnionResource } from 'api/resource'

class UserApi extends UnionResource {
  constructor() {
    super('user')
  }
  async update(id, data) {
    const result = await this.put({ url: `/${this.resource}/${id}`, data })
    return result.data
  }
  async updatePassword(data) {
    const result = await this.put({ url: `/${this.resource}/profile/password`, data })
    return result
  }

  async getStripeCustomer(id) {
    const result = await this.get({ url: `/${this.resource}/${id}/stripe/customer` })
    return result
  }

  async updateStripeCustomer(id, data) {
    const result = await this.put({ url: `/${this.resource}/${id}/stripe/customer`, data })
    return result
  }
}

export default UserApi
