import unescape from 'lodash/unescape'
import { POST_TYPES } from 'utils/stories'

export const capitalize = (str) => {
  const [first = '', ...rest] = str.split('')
  return [first.toUpperCase(), ...rest.map((letter) => letter.toLowerCase())].join('')
}

export const truncateString = (string, characterCount) =>
  string?.length > characterCount ? string.slice(0, characterCount) + '...' : string

export const truncateHtmlString = (string, characterCount) =>
  truncateString(decodeHtmlString(string.replace(/<[^>]+>/g, '')), characterCount)

export const decodeHtmlString = (string) => unescape(string)

export const capitalizeAndDashCase = (string) => string.split('_').map(capitalize).join('-')

export const storyLink = (story) => {
  let href = `/${POST_TYPES[story.type]}/[id]/[slug]`
  let as = `/${POST_TYPES[story.type]}/${story.id}/${story.slug}`
  if (story.is_gold) {
    href = '/gold' + href
    as = '/gold' + as
  }
  return { href, as, prefetch: false }
}

export const playlistLink = (playlist) => {
  let href = `/playlist/[id]/[slug]`
  let as = `/playlist/${playlist.id}/${playlist.slug}`
  return { href, as, prefetch: false }
}

export const authorLink = (author) => {
  const slug = author.author_url.split('/').pop()
  return {
    href: '/bio/[id]/[slug]',
    as: `/bio/${author.id}/${slug}`,
    prefetch: false
  }
}
