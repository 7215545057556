export const POST_TYPES = Object.freeze({
  standard_post: 'blog',
  barstool_original: 'video',
  podcast: 'podcast',
  gallery: 'gallery',
  video_podcast: 'video-podcast',
  live: 'live',
  fundraiser: 'donate'
})

export const getStoryCategory = (story) => {
  if (story.category.length) {
    return story.category[0].name
  } else if (Object.keys(story.category).length) {
    return story.category.name
  } else {
    return 'No category'
  }
}

export const parseStoryUrl = (story) => {
  // Construct new story url
  let url = `/${POST_TYPES[story.type]}/${story.id}/${story.slug}`

  // Prefix gold for all gold stories
  if (story.is_gold) {
    url = '/gold' + url
  }

  return url
}

export const parseVideoDuration = (duration) => {
  switch (duration) {
    case 60 * 1000 > duration > 0:
      return '0-1 min'
    case 120 * 1000 > duration > 60 * 1000 + 1:
      return '1-2 min'
    default:
      return '2 min'
  }
}

export const getTitleExtras = (story) => {
  return story.brand_name || story.post_type_meta?.podcast?.show?.title
}
