import { customer } from './customer'

export const purchase = {
  charge: {
    card: { brand: customer.default_source.brand, last4: customer.default_source.last4 },
    id: 'ch_1HL9PDGa3G5ozdhHcLfGTqfF',
    amount: 1999,
    currency: 'usd',
    customer: customer.id,
    payment_method: customer.default_source.id
  },
  status: 'succeeded',
  comped: false,
  user: customer.id,
  event: { status: 'live', name: 'RNR 12', date: '2020-10-23T23:00:00.000Z', price: 19.99, id: '0976f637' },
  price: 19.99,
  tax: 0,
  created_at: '2020-08-28T15:22:18.900Z',
  updated_at: '2020-08-28T15:22:19.916Z',
  id: 'aHNWhnGXmDvrNz8uVrB4rr'
}

export const purchase2 = {
  charge: {
    card: { brand: 'Visa', last4: '4242' },
    id: 'ch_1HclLqGa3G5ozdhHMG0LOGQB',
    amount: 1999,
    currency: 'usd',
    customer: 'cus_IDAEJZ4eZIeJlC',
    payment_method: 'card_1HclLoGa3G5ozdhHgjMcasa0'
  },
  status: 'succeeded',
  comped: false,
  user: '3194272',
  event: { status: 'live', name: 'RNR 12', date: '2020-10-23T23:00:00.000Z', price: 19.99, id: '90676f73' },
  price: 19.99,
  tax: 0,
  spent: 19.99,
  created_at: '2020-10-16T05:19:38.607Z',
  updated_at: '2020-10-16T05:19:39.381Z',
  id: '5eczZGSptsjucEUfGRUkGW'
}
