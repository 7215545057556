import { observable, flow } from 'mobx'
import { eventsApi, purchasesApi } from 'api'
import { CommonStore } from 'stores/common'
import storage from 'lib/storage'
import retriable from 'utils/retriable'

const eventNavItems = (event) => {
  const items = [
    // { title: 'Attend Event', href: event?.metadata?.tickets_url, primary: true, hasDropdown: false, external: true },
    {
      title: 'Fighter Sign Up',
      href: event?.metadata?.fighter_signup_url ?? '/participate',
      primary: true,
      hasDropdown: false,
      external: false,
      hidden: event?.metadata?.fighter_signup_active === false
    },
    {
      title: 'Ring Girl Sign Up',
      href: event?.metadata?.ring_girl_signup_url ?? '/participate',
      primary: true,
      hasDropdown: false,
      external: false,
      hidden: event?.metadata?.ring_girl_signup_active === false
    },
    {
      title: 'FAQ',
      href: 'https://help.roughnrowdybrawl.com/hc/en-us/articles/360048901811',
      primary: true,
      hasDropdown: false,
      external: true,
      hidden: event?.metadata?.faq_active ? false : true
    }
  ]
  if (event.metadata?.nav_bar_text_link?.active) {
    items.push({
      title: event.metadata.nav_bar_text_link.label,
      href: event.metadata.nav_bar_text_link.destination,
      primary: true,
      external: true,
      hidden: false
    })
  }
  return items
}

export const EventsStore = () => {
  const findRNR = flow(function* ({ sort = 'date', status = 'presale,upcoming,live,ended,on_demand', ...params } = {}) {
    const response = yield this.find({ type: 'rough_n_rowdy', sort, status, ...params })
    return response
  })

  const purchaseRNR = flow(function* (eventId, { zip = null } = {}) {
    this.purchaseStatus = 'PENDING'
    // combine idempotencyKey with eventId to ensure uniqueness across purchases of different events
    const idempotencyKey = storage.getIdempotencyKey(eventId)
    try {
      // todo: validate zip key in api
      const purchase = yield retriable(
        () => purchasesApi.createPurchase({ event_id: eventId, zip, idempotency_key: idempotencyKey }),
        {
          maxAttempts: 3
        }
      )
      this.purchaseStatus = 'RESOLVED'

      // remove idempotencyKey from storage if purchase was successful. By removing idempotencyKey from storage, a new one will be
      // generated on the fly next time a purchase is made
      storage.setIdempotencyKey(eventId, null)
      return purchase
    } catch (err) {
      this.purchaseStatus = 'ERROR'

      // do nothing for error statuses >= 500 so that same key is reused on any subsequent requests
      if (err.status < 500) {
        // remove idempotencyKey from storage if error code < 500, purchases with 4xx error codes should be
        // retried with different idempotency key. By removing idempotencyKey from storage, a new one will be
        // generated on the fly next time a purchase is made
        storage.setIdempotencyKey(eventId, null)
      }
      throw err
    }
  })

  const promoPurchaseRNR = flow(function* (eventId, { promoCode = '' } = {}) {
    const response = yield this.api.promoPurchase({ event_id: eventId, promo_code: promoCode })
    return response
  })

  const readVideoSource = flow(function* (eventId) {
    const response = yield this.api.readVideoSource(eventId)
    this.videoSource = response
    return response
  })

  const getNavItems = function () {
    if (this.current) {
      return eventNavItems(this.current)
    }
    if (this.items.length > 0) {
      return eventNavItems(this.items[0])
    }
    return []
  }

  return observable({
    ...CommonStore,
    api: eventsApi,
    purchaseStatus: 'IDLE',
    videoSource: null,
    findRNR,
    purchaseRNR,
    promoPurchaseRNR,
    getNavItems,
    readVideoSource
  })
}

EventsStore.cacheKey = 'EventsStore'

export default EventsStore
