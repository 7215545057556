import qs from 'querystring'
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import { withLogger } from 'lib/logger'

const logger = withLogger('GA')

let _initialized = false

export const initialize = () => {
  if (_initialized) return
  _initialized = true

  ReactGA.initialize(process.env.GA_TRACKING_ID)
  ReactGA.plugin.require('ecommerce')
  ReactGA.set({ transport: process.env.GA_TRANSPORT })

  ReactGA4.initialize(process.env.GA4_TRACKING_ID)
  ReactGA4.set({ transport: process.env.GA_TRANSPORT })
}

export const set = (...args) => {
  return ReactGA.set(...args)
}

export const sendPurchase = (purchase, event) => {
  const transaction = {
    id: purchase.id,
    affiliation: 'roughnrowdy',
    revenue: purchase.spent,
    tax: purchase.tax
  }
  const item = {
    id: transaction.id,
    name: event.name,
    sku: event.id,
    price: event.price,
    quantity: 1
  }
  ReactGA.plugin.execute('ecommerce', 'addTransaction', transaction)
  ReactGA.plugin.execute('ecommerce', 'addItem', item)
  ReactGA.plugin.execute('ecommerce', 'send')

  gtag('event', 'purchase', transformPurchase(purchase, item))
}

function transformPurchase(purchase, item) {
  return {
    transaction_id: purchase.id,
    value: purchase.spent,
    currency: 'USD',
    affiliation: 'roughnrowdy',
    tax: purchase.tax,
    items: [
      {
        index: 0,
        item_id: item.id,
        item_name: item.name,
        affiliation: 'roughnrowdy',
        quantity: 1,
        price: item.price
      }
    ]
  }
}

export const sendPageView = (page, title) => {
  const query = qs.parse(window.location.search.slice(1))

  const options = {
    page,
    location: `${window.location.origin}${window.location.pathname}${window.location.search}`
  }

  if (query.utm_source) options.campaignSource = query.utm_source
  if (query.utm_medium) options.campaignMedium = query.utm_medium
  if (query.utm_campaign) options.campaignName = query.utm_campaign
  if (query.utm_content) options.campaignContent = query.utm_content
  if (query.utm_term) options.campaignKeyword = query.utm_term

  ReactGA.set(options)
  ReactGA4.set(options)
  logger.log('pageView():', title)
  ReactGA.pageview(page, [], title)
  ReactGA4.send({ hitType: 'pageview', page, title })
}

/** @function sendEvent
 * @param {string} category - Required. A top level category for these events.
 * @param {string} action - Required - A description of the behaviour.
 * @param {Object} options - Optional - additional options to be sent.
 * @param {string} options.label - Optional. More precise labelling of the related action.
 * @param {int} options.value - Optional. A means of recording a numerical value against an event.
 * @param {boolean} options.nonInteraction - Optional. If an event is not triggered by a user interaction, but instead by our code.
 * @param {string} options.transport - Optional. This specifies the transport mechanism with which hits will be sent.
 */
export const sendEvent = (
  category,
  action,
  { label, value, nonInteraction = false, transport = process.env.GA_TRANSPORT } = {}
) => {
  logger.log('event():', category, action)
  ReactGA.event({ category, action, label, value, nonInteraction, transport })
  ReactGA4.event({ category, action, label, value, nonInteraction, transport })
}

export const siteHeaderEvents = {
  logo: () => sendEvent('Header', 'Logo'),
  appDownloadApple: () => sendEvent('Header', 'App Download Apple', { transport: 'beacon' }),
  appDownloadAndroid: () => sendEvent('Header', 'App Download Android', { transport: 'beacon' }),
  marketing: () => sendEvent('Header', 'Marketing'),
  terms: () => sendEvent('Header', 'Terms'),
  privacy: () => sendEvent('Header', 'Privacy'),
  contentPolicy: () => sendEvent('Header', 'Content Policy'),
  navLink: (item) => sendEvent('Header', 'Nav Link', { label: item }),
  myFeed: () => sendEvent('Header', 'My Feed'),
  download: () => sendEvent('Header', 'App Download Icon'),
  account: () => sendEvent('Header', 'Account'),
  favorites: () => sendEvent('Header', 'Account - Favorites')
}

export const footerEvents = {
  download: () => sendEvent('Footer', 'Download App'),
  facebook: () => sendEvent('Footer', 'Facebook'),
  twitter: () => sendEvent('Footer', 'Twitter'),
  instagram: () => sendEvent('Footer', 'Instagram'),
  youtube: () => sendEvent('Footer', 'YouTube'),
  marketing: () => sendEvent('Footer', 'Marketing'),
  terms: () => sendEvent('Footer', 'Terms'),
  privacy: () => sendEvent('Footer', 'Privacy'),
  contentPolicy: () => sendEvent('Footer', 'Content Policy'),
  subscriptionTerms: () => sendEvent('Footer', 'Subscription Terms'),
  link: (item) => sendEvent('Footer', 'Link', { label: item })
}

const sendVideoEvent = (action, options) => sendEvent('Content', action, options)

export const videoEvents = {
  start: () => sendVideoEvent('video started'),
  play: () => sendVideoEvent('video played'),
  pause: () => sendVideoEvent('video paused'),
  end: () => sendVideoEvent('video finished'),
  percentPlayed: ({ value, label }) =>
    sendEvent('Brightcove Player', 'Percent played', { label, value, nonInteraction: true }),
  seekStart: ({ value, label }) => sendEvent('Brightcove Player', 'Seek start', { label, value, nonInteraction: true }),
  seekEnd: ({ value, label }) => sendEvent('Brightcove Player', 'Seek end', { label, value, nonInteraction: true }),
  watched: () => sendVideoEvent('video watched'),
  adsStart: () => sendVideoEvent('video ad started'),
  adsFinish: () => sendVideoEvent('video ad finished'),
  adsPlay: () => sendVideoEvent('video ad played'),
  adsPause: () => sendVideoEvent('video ad paused')
}

export const videosPageEvents = {
  heroClicked: (videoUrl) =>
    sendEvent('Video Page Interactions', 'Hero | Featured', { label: videoUrl, nonInteraction: true }),
  showsSection: (label) =>
    sendEvent('Video Page Interactions', 'Static Section | Shows', { label, nonInteraction: true }),
  latestVideos: (label) =>
    sendEvent('Video Page Interactions', 'Static Section | Latest Videos', { label, nonInteraction: true }),
  playlist: (playlistName, label) =>
    sendEvent('Video Page Interactions', `Below Fold Playlists | ${playlistName}`, { label, nonInteraction: true })
}

export const storyDetailEvents = {
  shareButton: (location, medium) => sendEvent(location, 'Share', medium),
  clickTag: (tag) => sendEvent('Content', 'Tag Clicked', { label: tag }),
  videoStarted: () => sendEvent('Content', 'video started'),
  videoFinished: () => sendEvent('Content', 'video finished'),
  videoAdStarted: () => sendEvent('Content', 'video ad started'),
  videoAdFinished: () => sendEvent('Content', 'video ad finished'),
  videoAutoplayed: (id, name, count, session = '') => {
    const actionString = `${id} | ${name} | ${session}`
    sendEvent('Video Autoplay', actionString, { label: `${count}`, nonInteraction: true })
  }
}

export const playlistVideoSidebarEvents = {
  itemClicked: (url) => sendEvent('playlistvideoSidebar', 'item click', { label: url }),
  loadMore: () => sendEvent('playlistvideoSidebar', 'load more')
}

export const brandVideoSidebarEvents = {
  itemClicked: (url) => sendEvent('brandvideoSidebar', 'item click', { label: url }),
  loadMore: () => sendEvent('brandvideoSidebar', 'load more')
}

export const popularVideoSidebarEvents = {
  itemClicked: (url) => sendEvent('popularvideoSidebar', 'item click', { label: url }),
  loadMore: () => sendEvent('popularvideoSidebar', 'load more')
}

export const customDimensions = {
  setPageType: (type) => ReactGA.set({ dimension3: type }),
  setStoryDimensions: (o) => {
    ReactGA.set({
      dimension3: o.pageType,
      dimension4: o.date,
      dimension5: o.author,
      dimension6: o.category,
      dimension7: o.tags,
      dimension8: o.postType,
      dimension9: o.brandName,
      dimension10: o.nsfw,
      dimension11: o.id
    })
    ReactGA4.set({
      dimension3: o.pageType,
      dimension4: o.date,
      dimension5: o.author,
      dimension6: o.category,
      dimension7: o.tags,
      dimension8: o.postType,
      dimension9: o.brandName,
      dimension10: o.nsfw,
      dimension11: o.id
    })
  },
  clearDimension: (dimension) => {
    ReactGA.set({
      [dimension]: null
    })
    ReactGA4.set({
      [dimension]: null
    })
  },
  clearDimensions: () => {
    ReactGA.set({
      dimension3: null,
      dimension4: null,
      dimension5: null,
      dimension6: null,
      dimension7: null,
      dimension8: null,
      dimension9: null,
      dimension10: null,
      dimension11: null
    })
    ReactGA.set({
      dimension3: null,
      dimension4: null,
      dimension5: null,
      dimension6: null,
      dimension7: null,
      dimension8: null,
      dimension9: null,
      dimension10: null,
      dimension11: null
    })
  }
}

const ga = {
  initialize,
  set,
  sendPageView,
  customDimensions,
  siteHeaderEvents,
  footerEvents
}

export default ga
