import { useEffect } from 'react'
import { format } from 'date-fns'
import { useUserId } from 'hooks'
import storage from 'lib/storage'
import ga from 'lib/ga'
import { getStoryCategory } from 'utils/stories'
import { pageView as pixelPageView } from 'lib/pixels'
import { UserStore } from 'stores/user'
import { AuthStore } from 'stores/auth'
import { useStore } from './store'
import { identify } from '../lib/whoami'

export const useAnalytics = () => {
  useEffect(() => {
    ga.initialize()
  }, [])
}

export const useWhoamiIdentification = () => {
  const authStore = useStore(AuthStore)
  const userStore = useStore(UserStore)
  const user = userStore?.current ?? {}
  const loggedIn = authStore.loggedIn

  useEffect(() => {
    if (loggedIn && user.id) {
      identify({
        barstoolUserID: user.id,
        stripeCustomerID: user?.stripe_customer
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, loggedIn])
}

export const usePageView = (handler = () => {}, deps = []) => {
  const userId = useUserId()
  const userType = storage.userType

  useAnalytics()

  useEffect(() => {
    setTimeout(() => {
      const overrides = handler(ga) || {}

      // Grab current page url
      const url = new URL(location.href)

      // Apply additional query params from overrides
      for (const [key, value] of Object.entries(overrides.query ?? {})) {
        if (value === undefined) continue
        url.searchParams.set(key, value)
      }

      // Set user id if we have it
      ga.set({ userId })

      userType && ga.set({ dimension1: userType })

      // Send page view based on updated url
      ga.sendPageView(url.pathname + url.search, overrides.title ?? document.title)

      // pixel pageview
      pixelPageView()
    }, 200)
    return () => {
      ga.customDimensions.clearDimensions()
    }
  }, deps)
}

export const useStoryPageView = (story) => {
  usePageView(() => {
    // Set custom dimensions
    ga.customDimensions.setStoryDimensions({
      author: story.author.name,
      pageType: 'story',
      date: format(new Date(story.date), 'M/d/yyyy h:mm a'),
      category: getStoryCategory(story),
      tags: story.tags.join('|'),
      postType: story.type,
      brandName: story.brand_name,
      nsfw: story.nsfw ? 'nsfw' : null,
      id: story.id
    })

    // Return overrides
    return { title: story.title }
  }, [story.id])
}

export const useFeedPageView = (pagination, { pageType = 'Index' } = {}, deps = []) => {
  usePageView(() => {
    // Set GA page type
    ga.customDimensions.setPageType(pageType)

    return {
      query: {
        page: pagination.page > 1 ? pagination.page : undefined
      }
    }
  }, [pagination.page, pageType, ...deps])
}
