import { PPVResource } from 'api/resource'

class PurchasesApi extends PPVResource {
  constructor() {
    super('purchases')
  }

  async createPurchase(data) {
    const response = await this.post({ url: `/${this.resource}`, data })
    return response
  }
}

export default PurchasesApi
