import { observable } from 'mobx'
import { v4 as uuid } from 'uuid'

const Store = {
  items: [],

  addNotification({ type, message, duration }) {
    const id = uuid()
    const notification = { type, message, duration, id }
    this.items = [...this.items, notification]
    const timeoutId = setTimeout(() => {
      this.removeNotification(id)
      clearTimeout(timeoutId)
    }, duration)
    return this.items
  },

  removeNotification(id) {
    this.items = this.items.filter((notification) => notification.id !== id)
    return this.items
  }
}

export const NotificationStore = () => {
  return observable({
    ...Store
  })
}

NotificationStore.cacheKey = 'NotificationStore'

export default NotificationStore
