import { useEffect } from 'react'
import { UserStore } from 'stores/user'
import { useStore } from './store'
import intercom from 'lib/intercom'

export const useIntercom = () => {
  const userStore = useStore(UserStore)
  let options = {
    app_id: process.env.INTERCOM_APP_ID
  }
  if (userStore.current) {
    options.name = `${userStore.current.user?.first_name ?? ''} ${userStore.current.user?.last_name ?? ''}`
    options.email = userStore.current.user?.email ?? ''
  }

  useEffect(() => {
    intercom.load(options)
  }, [])
}
