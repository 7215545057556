import { UnionResource } from './resource'

class AnalyticsApi extends UnionResource {
  constructor() {
    super('analytics')
  }

  async updateKlaviyo(data) {
    return await this.put({ url: `${this.resource}/klaviyo`, data })
  }
}

export default AnalyticsApi
