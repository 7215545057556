import AnalyticsApi from 'api/analytics'
import AuthApi from 'api/auth'
import ConfigApi from 'api/config'
import EventsApi from 'api/events'
import EventsAuthApi from 'api/events-auth'
import FormsApi from 'api/forms'
import PlaylistsApi from 'api/playlists'
import PurchasesApi from 'api/purchases'
import StatusApi from 'api/status'
import StoriesApi from 'api/stories'
import UserApi from 'api/user'

const analyticsApi = new AnalyticsApi()
const authApi = new AuthApi()
const configApi = new ConfigApi()
const eventsApi = new EventsApi()
const eventsAuthApi = new EventsAuthApi()
const formsApi = new FormsApi()
const playlistsApi = new PlaylistsApi()
const purchasesApi = new PurchasesApi()
const statusApi = new StatusApi()
const storiesApi = new StoriesApi()
const userApi = new UserApi()

export {
  analyticsApi,
  authApi,
  configApi,
  eventsApi,
  eventsAuthApi,
  formsApi,
  playlistsApi,
  purchasesApi,
  statusApi,
  storiesApi,
  userApi
}
