import { useEffect } from 'react'

// locks body scrolling - useful for open modals, mobile nav, etc
export const useBodyScrollLock = (shouldLock) => {
  useEffect(() => {
    return () => {
      document.body.style.removeProperty('overflow')
      document.body.style.removeProperty('position')
      document.body.style.removeProperty('width')
    }
  }, [])
  useEffect(() => {
    if (shouldLock) {
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
    } else {
      document.body.style.removeProperty('overflow')
      document.body.style.removeProperty('position')
      document.body.style.removeProperty('width')
    }
  }, [shouldLock])
}

export default useBodyScrollLock
