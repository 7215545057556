import React from 'react'
import { observer } from 'mobx-react-lite'
import GlobalStyles from 'components/GlobalStyles'
import SiteHeader from 'components/SiteHeader'
import SiteFooter from 'components/SiteFooter'
import NotificationContainer from 'components/Notification'
import AnnouncementBanner from 'components/AnnouncementBanner'
import { useStore } from 'hooks'
import THEME from 'utils/theme'
import NavigationStore from 'stores/navigation'
import Link from 'next/link'

const SiteLayout = ({
  children,
  background = '#000000',
  useAvailableEventLogo,
  footerBackgroundColor,
  footerForegroundColor,
  minimalHeader = false,
  showNavBarCTA = false
}) => {
  const navigationStore = useStore(NavigationStore)

  return (
    <>
      <div className='layout'>
        <SiteHeader
          minimalHeader={minimalHeader}
          showNavBarCTA={showNavBarCTA}
          useAvailableEventLogo={useAvailableEventLogo}
        />
        <div className='layout__overlayContainer' onClick={() => navigationStore.close()}>
          <div className='layout__overlay' />
          <main className='layout__main'>{children}</main>
          <SiteFooter backgroundColor={footerBackgroundColor} foregroundColor={footerForegroundColor} />
        </div>
        <NotificationContainer />
        <GlobalStyles />
      </div>
      <style jsx>{`
        .layout {
          background: ${background};
          overflow-x: hidden;
          min-height: 100vh;
        }
        .layout__overlayContainer {
          position: relative;
        }
        .layout__overlay {
          display: none;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 10;
          background: rgba(0, 0, 0, 0.5);
        }
        .layout__main {
          min-height: calc(100vh - 231px);
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .layout__main {
            min-height: calc(100vh - 190px);
          }
        }
      `}</style>
    </>
  )
}

export default observer(SiteLayout)
