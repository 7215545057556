import { useState } from 'react'

import random from 'lodash/random'

import { useStore, useInterval } from 'hooks'
import { AuthStore } from 'stores/auth'

const HEARTBEAT_MIN = process.env.HEARTBEAT_MIN
const HEARTBEAT_MAX = process.env.HEARTBEAT_MAX

export const useAuthHeartbeat = () => {
  const authStore = useStore(AuthStore)

  const [intervalDuration, setIntervalDuration] = useState(random(HEARTBEAT_MIN, HEARTBEAT_MAX))

  useInterval(
    async () => {
      if (authStore.loggedIn) {
        try {
          await authStore.heartbeat()
          setIntervalDuration(random(HEARTBEAT_MIN, HEARTBEAT_MAX))
        } catch (err) {
          if (err?.verified === false) {
            authStore.setError('maxSessions')
            authStore.logout()
          }
        }
      }
    },
    intervalDuration * 1000,
    [authStore.loggedIn]
  )
}
