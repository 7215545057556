async function delay(timeout) {
  return new Promise((resolve) => setTimeout(resolve, timeout))
}

export const retriable = async (task, { maxAttempts = 5, retryDelay = 2000 } = {}, attempt = 0) => {
  // Delay request if second attempt
  await delay(attempt * retryDelay)

  try {
    return await task()
  } catch (err) {
    // Parse status code
    const statusCode = err.status || err.response?.status || 500
    // Re-throw error if we receive a 4xx
    if (statusCode >= 400 && statusCode < 500) {
      throw err
    }
    // Re-throw error if we reached max number of attempts
    if (attempt >= maxAttempts - 1) {
      throw err
    }

    // Retry task
    return retriable(task, { maxAttempts, retryDelay }, attempt + 1)
  }
}

export default retriable
